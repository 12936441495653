import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(without-partners)/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BreadCrumbs/BreadCrumbs.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainPage/components/SubscribeToUpdatesBlock/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/hocs/Container/Container.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/MainPage/components/CertificatesSection/CertificatesSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/Typography/Typography.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/SocialLinksBlock/SocialLinksBlock.module.scss");
